<template>
    <div id="services-block">     
        <h1>Our Services</h1>
        <div class="services-container">

            <router-link class="block" to="/services#integrative-healthcare-coaching">                        
                <span>💖</span>
                <h2 class="title">Integrative Healthcare Coaching</h2>                
                <p class="desc">Available online or in-person, address the root causes of your illness, symptoms, & weight issues</p>            
            </router-link>

            <router-link class="block" to="/services#bio-individual-personal-training">            
                <span>🏋🏽‍♀️</span>
                <h2 class="title">Bio-Individual Personal Training</h2>                
                <p class="desc">In-person one-on-one personal training at our studio in Okotoks, Alberta</p>            
            </router-link>
            
            <router-link class="block" to="/services#reiki">            
                <span>🌀</span>
                <h2 class="title">Reiki & Sound Therapy</h2>
                <p class="desc">In-person energy & sound therapies to address your emotional & energetic health at our studio in Okotoks, Alberta</p>            
            </router-link>

            <router-link class="block" to="/services#bio-individual-exercise-programs">            
                <span>🏃🏼‍♂️</span>
                <h2 class="title">Bio-Individual Exercise Programs</h2>                
                <p class="desc">Online-only, completely customized fitness programs which include a virtual movement assessment to address your unique goals, muscle imbalances, chronic pain, & more</p>            
            </router-link>
            

        </div>
    </div>
</template>

<script>

export default {

}
</script>

<style scoped>
    .services-container {
        display:grid;
        grid-template-columns: repeat(4, 1fr);   
        grid-gap:40px;           
    }

    h1 {
        padding:100px 0px 50px;
        text-align:center;
    }

    .block {
        width:100%;                
        border:3px solid var(--color-purple);
        border-radius: 20px;        
        padding:40px;
        box-sizing: border-box;   
        display:grid;
        grid-gap:10px;
        align-items: center;
        text-align: center;             
        cursor: pointer;
        transition:0.5s;
    }

    .block:hover {
        box-shadow:0px 0px 10px var(--color-purple);
    }
    
    .block span {
        font-size:40px;
    }
    
    @media (max-width:1180px) {
        .services-container {            
            grid-template-columns: repeat(2, 1fr);                    
        }
    }
    
    @media (max-width:660px) {
        .services-container {            
            grid-template-columns: 1fr;                    
        }
    }
</style>
