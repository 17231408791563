<template>
    <footer id="footer">
        <section>
            <h3>Sitemap</h3>
            <ul>
                <li>Home</li>
                <li>About</li>
                <li>Booking</li>
                <li>Services</li>
                <li>Products</li>
            </ul>
        </section>
        <section>
            <h3>About</h3>
            <ul>
                <li>About PHI</li>
                <li>What is an IHP?</li>                
            </ul>
        </section>
        <section>
            <h3>Services</h3>
            <ul>
                <li>Root Cause Healthcare Coaching</li>
                <li>Bio-Individual Personal Training</li>
                <li>Reiki & Sound Therapy</li>
                <li>Bio-Individual Exercise Programs</li>                
            </ul>
        </section>
        <section>
            <h3>Contact</h3>
            <ul>
                <li>Call: +1-647-999-1316</li>
                <li>Email: positivehealthinstitute1@gmail.com</li>
                <li>Book a consultation</li>                        
            </ul>
        </section>
    </footer>   
    <p class="copyright">© 2022 Positive Health Institute. All rights reserved. 👣</p> 
</template>

<script>
export default {
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    footer {  
        display: flex;
        justify-content: space-between;              
        flex-flow: row wrap;
        position:relative;    
        width: 100%;                       
        background: var(--color-dark);        
        border-radius:40px;           
        padding: 30px;
        box-sizing: border-box;
        margin-bottom:20px;   
        margin-top:100px;     
    }

    footer p {
        color:white;
    }

    ul {
        margin:0;
        padding-left:20px;
    }

    li, h3 {
        color:white;
    }

    section  {
        display:inline-block;
        margin-bottom:20px;
    }

    .copyright {
        text-align: center;
        margin-bottom:20px;
    }

</style>
