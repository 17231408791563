<template>
    <header>
        <div class="logo">
            <router-link to="/">
                <img alt="Vue logo" src="../assets/logo.png">
            </router-link>
        </div>
        <div id="nav">                
            <router-link to="/about">About</router-link>
            <router-link to="/services">Services</router-link>            
            <!-- <router-link to="/products">Products</router-link> -->
            <!-- <router-link to="/contact">Contact</router-link> -->
            <router-link to="/#footer">Contact</router-link>
            <!-- <router-link to="/authenticate" class="btn">Sign In</router-link> -->
            <!-- <router-link to="/booking" class="btn">Booking</router-link> -->
            <a href="https://calendly.com/positivehealthinstitute" target="_blank" class="btn">Booking</a>
        </div>

        <div class="burger-menu" @click="openMobileMenu" >
             <span></span>
             <span></span>
             <span></span>            
        </div>
         <div v-if="mobile" ref="mobileMenu" class="menu-items">
             <div class="container">
                <span class="close-btn" ref="closeMenu" @click="closeMobileMenu">×</span>                         
                <div class="items">
                    <router-link to="/" @click="closeMobileMenu">Home</router-link>
                    <router-link to="/about" @click="closeMobileMenu">About</router-link>
                    <router-link to="/services" @click="closeMobileMenu">Services</router-link>            
                    <!-- <router-link to="/products">Products</router-link> -->
                    <!-- <router-link to="/contact" @click="closeMobileMenu">Contact</router-link> -->
                    <router-link to="/#footer" @click="closeMobileMenu">Contact</router-link>
                    <!-- <router-link to="/authenticate" class="btn">Sign In</router-link> -->
                    <!-- <router-link to="/booking" class="btn" @click="closeMobileMenu">Booking</router-link> -->
                    <a href="https://calendly.com/positivehealthinstitute" target="_blank" class="btn" @click="closeMobileMenu">Booking</a>
                </div>
            </div>
        </div>
        
    </header>
</template>

<script>
import { ref } from 'vue'
export default {

setup() {
    const mobile = ref(false)
    const mobileMenu = ref(null)
    const openMobileMenu = () => {
        mobile.value = !mobile.value;
        setTimeout(() => {
            mobileMenu.value.classList.add('active');
        },200)
        console.log('opening?', mobile.value)
    } 

    const closeMenu = ref(null)
    const closeMobileMenu = () => {        
        mobileMenu.value.classList.remove('active');  
        setTimeout(() => {
            mobile.value = !mobile.value;
        },1000)      
    }
    return {openMobileMenu, mobile, mobileMenu, closeMobileMenu, closeMenu}
},

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header { 
    width: 100%;
    display: inline-flex;
    justify-content: space-between;  
    align-items: center;
    padding:20px 0px;  
    box-sizing: border-box;
}

.logo a img{
    height:50px;
}

#nav a{
    margin: 0px 10px;
    font-weight:bolder;
}
#nav a:first-child{margin-left: 0px;}
#nav a:last-child{margin-right: 0px;}

#nav a.btn,
.items a.btn {
    background:var(--color-purple);
    padding:10px 20px;
    border-radius:20px;
    color:white;
}

.container {
    text-align: center;
    position:relative;
    padding:100px;    
}

.items {
    display:inline-grid;
}

.items a {
    font-size:20px;
    font-weight:bolder;
    margin:10px;
}

.close-btn {
    font-size:20px;
    font-weight:bolder;
    position: absolute;
    top:20px;
    right:20px;
    cursor:pointer;
}

.burger-menu {
    display:none;
    cursor:pointer;
}

.burger-menu span {  
    display: block;              
    width: 20px;
    height:2px;    
    background:var(--color-purple);    
    margin-bottom:4px;    
}

.menu-items {
    position: fixed;
    display: block;
    height: 100vh;
    width: 100vw;
    background: white;
    top: 0;
    left: 100%;
    z-index: 999;
    transition: 1s;
}

.menu-items.active{
    left: 0%;
}

@media (max-width:770px) {
    .burger-menu {
        display: block;
    }

    #nav {
        display:none;
    }
}


</style>
