<template>
    <div class="about-block">     
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="300px" y="100px"
        viewBox="0 0 300 100">
            <path style="fill:#FFFFFF" d="M300,75v13H0V75h3.63c14.43,0,28.13-6.78,36.53-18.51C64.65,22.29,104.72,0,150,0s85.35,22.29,109.84,56.49
            c8.4,11.73,22.1,18.51,36.53,18.51H300z"/>
        </svg>
        <h1>Meet Anna</h1>
        <p>
            Anna Tsatsura is a passionate Integrative Health Practitioner, Personal Trainer, and Reiki Practitioner helping clients achieve true health, vitality, and wellness, globally online & locally at her studio in Okotoks, Alberta, Canada.
        </p>           
        <router-link to="/about" class="cta-btn">
            Learn more about Anna and Intergrative Healthcare.
        </router-link>            
    </div>
</template>

<script>

export default {

}
</script>

<style scoped>
    .about-block {        
        margin: 0px auto;
        padding: 100px 40px 40px;
        border: 1px white;        
        border-radius: 40px;
        position: relative;
        background: #f4f4f4;
        text-align:center;
    }
    
    p, a {    
        max-width:800px;   
        margin:20px auto; 
        font-size:20px;        
        font-weight:bolder;
    }

    svg {
        width: 300px;
        height: 100px;
        position: absolute;
        top: -25px;
        left: 50%;
        margin-left: -150px;
        transform: rotate(180deg);
    }	

    .cta-btn {
        color: var(--color-coral);        
    }

    .cta-btn:hover {
        text-decoration:underline;
    }

    @media (max-width:660px) {
        p, a {
            font-size:16px;
        }
    }

</style>
