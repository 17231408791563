<template>
  <div class="hero">        
    <h1 class="title">Positive Health Institute</h1>
    <p class="content">
        Address the Root Causes to your Chronic Illness, Autoimmune Disease, Hormonal Imbalance, Metabolic Syndrome, Digestive Distress, Low Energy, & Stubborn Weight Loss.
    </p>              
    <!-- <router-link to="/booking" class="cta-btn">Book your complimentary consultation 😊</router-link>                 -->
    <a href="https://calendly.com/positivehealthinstitute" target="_blank" class="cta-btn">Book your complimentary virtual consultation 😊</a>

    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="300px" y="100px"
	 viewBox="0 0 300 100">
        <path style="fill:#FFFFFF" d="M300,75v13H0V75h3.63c14.43,0,28.13-6.78,36.53-18.51C64.65,22.29,104.72,0,150,0s85.35,22.29,109.84,56.49
        c8.4,11.73,22.1,18.51,36.53,18.51H300z"/>
    </svg>
    <div class="circle">
        <img src="../../assets/anna01.jpg" alt="Anna Tsatsura"/>
    </div> 
  </div>  
</template>

<script>

export default {

}
</script>

<style scoped>
    
    .hero  {
        display:grid;
        grid-template-rows: 70px 1fr 50px;        
        align-items:center;   
        text-align: center;     
        position:relative;    
        width: 100%;        
        height:400px;        
        background: var(--main-gradient);        
        border-radius:40px;           
        padding: 60px 90px 100px;
        box-sizing: border-box;
        margin-bottom:130px;
    }
    
    .title {       
        margin:0; 
        font-size: 70px;
        color:white;        
    }         
    
    .content {
        color:white;
        font-weight:bolder;
        max-width:600px;
        margin:0px auto;
        font-size:18px;
    }

    .cta-btn {        
        margin:0 auto;
        padding: 8px 20px;
        border-radius: 15px;
        background:white;
        box-shadow:0px 3px 3px #1a1a1a;
        font-weight: bolder;
        transition:0.5s;
    }

    .cta-btn:hover {
        background:none;
        color:white;
        box-shadow:0px 0px 10px white;         
    }

    svg {
        width:300px;
        height:100px;
        position:absolute;
        bottom:-25px;
        left:50%;
        margin-left: -150px;;
    }	
    
    .circle {        
        width: 250px;
        height: 250px;
        border-radius: 100%;
        background: var(--main-gradient);
        position: absolute;
        left: 50%;
        bottom: -190px;
        margin-left: -125px;
        box-shadow: 0px 0px 10px #1a1a1a;
        z-index: 9;
    }

    img {
        object-fit: cover;
        width: 240px;
        height: 240px;
        border-radius: 100%;
        background: white;
        position: absolute;
        top: 5px;
        left: 50%;    
        margin-left: -120px;    
    }

    @media(max-width:1180px) {
        .title {
            font-size:5vw;
        }
    }

    @media(max-width:830px) {
        .title {
            font-size:40px;
        }
        .hero {
            padding: 60px 50px 100px;
        }
        .content {
            font-size:15px;
        }
    }

    @media(max-width:660px) {
        .title {
            font-size:30px;
            line-height:30px;
        }        
    }

    @media(max-width:500px) {
        .hero {
            padding: 30px 20px 100px;
        }
        
    }

</style>
