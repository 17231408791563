<template>
  <div class="home">
    <Hero />
    <AboutBlock />
    <ServicesBlock />    
  </div>
</template>


<script>
// @ is an alias to /src
import Hero from '@/components/home-page/Hero.vue'
import AboutBlock from '@/components/home-page/About-Block.vue'
import ServicesBlock from '@/components/home-page/Services-Block.vue'

export default {
  name: 'Home',
  components: {
    Hero,
    AboutBlock,
    ServicesBlock,
  }
}
</script>

<style scoped>
  h1 {
    text-align: center;
    padding:100px;
  }
</style>
